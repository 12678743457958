import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// import { WeatherWidget } from '@daniel-szulc/react-weather-widget';

// import Background from './components/Background';
// import BackgroundNew from './components/BackgroundNew';
import Test from './components/Test';
import MoonButton from './components/MoonButton';

// Define keyframes for the bouncing effect
const bounce = keyframes`
  0%, 100% {
    transform: rotate(10deg) translate(-10%, 30%);
  }
  50% {
    transform: rotate(10deg) translate(-5%, 25%);
  }
`;

// #a80534
// #ffb900
// #015941
// #007cc2
// #7d4802

const StyledApp = styled.main`
  position: relative;
  width: 100vw;
  height: 100dvh;
  margin: 0;
  overflow: hidden;

  .daniel-szulc-weather-widget {
    position: absolute;
    top: 10%;
  }

  .header-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: 700px) {
      top: 15%;
      }

    h2 {
      font-size: 98px;
      color: #ffb900;
      text-shadow: -2px -2px 0 #7d4802, 2px -2px 0 black, -2px 2px 0 black,
        2px 2px 0 black;
      letter-spacing: 6px;
      padding: 0;
      margin: 0;

      @media (max-width: 700px) {
        font-size: 68px;
      }
    }

    h4 {
      font-size: 32px;
      /* font-weight:200; */
      margin: 52px;
      color: #f3f2e6;
      opacity: 0.9;

      letter-spacing: 2px;
      /* text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black,
      2px 2px 0 black; */

      @media (max-width: 700px) {
        font-size: 22px;
      }

      @media (max-width: 500px) {
        font-size: 14px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    .socials {
      display: flex;
      gap: 50px;

      img {
        width: 65%;
      }
    }
  }

  .dan-img {
    position: absolute;
    width: 60%;
    bottom: 0;
    opacity: 0.8;
    animation: ${bounce} 5s ease-in-out infinite;
    z-index: 1;

    @media (max-width: 700px) {
      width: 80%;
    }

    @media (max-width: 500px) {
      width: 120%;
      bottom: -5vh;
    }
  }

  .im-not-leaving-text {
    /* font-family: 'Comic Sans MS', 'Comic Sans', cursive, sans-serif; */
    font-size: 70px;
    font-weight: 900;
    color: #a80534;
    position: absolute;
    bottom: 10%;
    right: 5%;
    transform: rotate(10deg);
    z-index: 2;
    letter-spacing: 3px;

    /* Add text shadow to create an outline effect */
    text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black,
      2px 2px 0 black;

    @media (max-width: 1100px) {
      font-size: 40px;
    }

    @media (max-width: 700px) {
      font-size: 30px;
      text-align: center;
      bottom: 30%;
      right: 0;
      width: 100%;
      transform: rotate(0deg) translate(0%, 0);
      position: relative;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
  }
`;

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay for demonstration purposes
  useEffect(() => {
    // This should ideally be replaced with actual loading logic for your Three.js assets
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust as necessary for your actual loading time

    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledApp>
      <Test />

      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {/* <WeatherWidget
            className="weather-widget"
            provider="openWeather"
            apiKey="7556d73c440f68e77a6315ae7fdce685"
            location="Tampa"
            tempUnit="F"
            windSpeedUnit="mps"
            lang="en"
          /> */}
          <div className="header-text">
            <h2>$DAN</h2>
            <h4>3ytQ1uY1XVJMuXcA5ndjpjA1aQ7etcHQCyqzuZxupump</h4>

            <div className="socials">
              <MoonButton size="40px" goTo="https://x.com/danonboat">
                <img src="/assets/icons/twitter.png" alt="twitter icon"></img>
              </MoonButton>

              <MoonButton size="40px" goTo="https://t.me/OfficialDANonSOL">
                <img src="/assets/icons/telegram.png" alt="telegram icon"></img>
              </MoonButton>

              <MoonButton
                size="40px"
                goTo="https://dexscreener.com/solana/79cwiddneb6nbbngvans1xupnlmho1ywrdrqpfxfwmpr"
              >
                <img
                  src="/assets/icons/dexscreener.png"
                  alt="dexscreener icon"
                ></img>
              </MoonButton>
            </div>
          </div>

          <h3 className="im-not-leaving-text">`I AM NOT FUCKING LEAVING!`</h3>

          <img
            className="dan-img"
            alt="dan on boat"
            src="/assets/images/dan-on-boat.png"
          />
        </>
      )}
    </StyledApp>
  );
}

export default App;
