import React from "react";
import styled from "styled-components";

const StyledMoonButton = styled.a`
  position: relative;
  width: ${(props) => (props?.size ? props.size : "40rem")};
  height: ${(props) => (props?.size ? props.size : "40rem")};

  border-radius: 50%;
  background-color: #f3f2e6;
  box-shadow: 0 0 8px 8px #f3f2e6, 0 0 1px 8px #f3f2e6, 0 0 20px 20px #f0e68c33;
  opacity: 0.85;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    width: ${(props) => (props?.size ? props.size : "20rem")};
    height: ${(props) => (props?.size ? props.size : "20rem")};
    box-shadow: 0 0 6px 6px #f3f2e6, 0 0 1px 6px #f3f2e6,
      0 0 16px 16px #f0e68c33;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:active {
    transform: scale(0.95);
  }
`;

export default function MoonButton({ children, goTo, size }) {
  return (
    <StyledMoonButton size={size} href={goTo} target="_blank">
      {children}
    </StyledMoonButton>
  );
}
