/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
// import { TextGeometry ,FontLoader} from 'three-stdlib';
// import { FontLoader } from 'three/addons/loaders/FontLoader.js';

const ThreeScene = () => {
  const mountRef = useRef(null);
  // const textRef = useRef(null);

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(0x11111f, 0.002);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    camera.position.set(0, 0, 1);
    camera.rotation.set(1.16, -0.12, 0.27);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setClearColor(scene.fog.color);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    // Add renderer to DOM
    mountRef.current.appendChild(renderer.domElement);

    // Resizing handling
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Lighting setup
    const ambientLight = new THREE.AmbientLight(0x555555);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffeedd);
    directionalLight.position.set(0, 0, 1);
    scene.add(directionalLight);

    const flash = new THREE.PointLight(0x015941, 50, 1, 1);
    flash.position.set(200, 300, 100);
    scene.add(flash);

    // Add helper to visualize flash position
    // const flashHelperGeometry = new THREE.SphereGeometry(10, 16, 16);
    // const flashHelperMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    // const flashHelper = new THREE.Mesh(flashHelperGeometry, flashHelperMaterial);
    // flashHelper.position.copy(flash.position);  // Set initial position to match flash
    // scene.add(flashHelper);

    // Cloud Textures and Particles setup
    const loader = new THREE.TextureLoader();
    const texture1 = loader.load("assets/images/vecteezy_rain-clouds-and-black-sky-textured-background_10123744_360-min.jpg");
    const texture2 = loader.load("assets/images/vecteezy_rain-clouds-and-black-sky-textured-background_10121810_444-min.jpg");
    const texture3 = loader.load("assets/images/vecteezy_rain-clouds-and-black-sky-textured-background_10121519_408-min.jpg");

  //   const fontLoader = new FontLoader();

  //   fontLoader.load( '/assets/fonts/Florida_Regular.json', function ( font ) {

	// const textGeometry = new TextGeometry( '$DAN', {
	// 	font: font,
	// 	size: 10,
	// 	depth: 5,
	// 	curveSegments: 12,
	// 	bevelEnabled: true,
	// 	bevelThickness: 10,
	// 	bevelSize: 8,
	// 	bevelOffset: 0,
	// 	bevelSegments: 5
	// } );

  // const textMaterial = new THREE.MeshLambertMaterial({ color: 0xffa500 });
  // const textMesh = new THREE.Mesh(textGeometry, textMaterial);
  // textMesh.position.set(0, 0, -70); // Center the text
  // // textMesh.position.set(-50, 0, -100); 
  // // textMesh.rotation.x = 0.2; // Add slight rotation
  // scene.add(textMesh);
  // textRef.current = textMesh; 

// } );


    const cloudParticles1 = [];
    const cloudParticles2 = [];
    const cloudParticles3 = [];
    
    const createClouds = (texture, cloudParticles) => {
      const geometry = new THREE.PlaneBufferGeometry(2000, 2000);
      const material = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
      });

      for (let i = 0; i < 25; i++) {
        const cloud = new THREE.Mesh(geometry, material);
        cloud.position.set(
          Math.random() * 800 - 400,
          500,
          Math.random() * 500 - 450
        );
        cloud.rotation.set(1.16, -0.12, Math.random() * 360);
        cloud.material.opacity = 0.8;
        cloudParticles.push(cloud);
        scene.add(cloud);
      }
    };

    createClouds(texture1, cloudParticles1);
    createClouds(texture2, cloudParticles2);
    createClouds(texture3, cloudParticles3);

    // Animation loop
    const animate = () => {
      cloudParticles1.forEach(p => (p.rotation.z -= 0.0004));
      cloudParticles2.forEach(p => (p.rotation.z -= 0.0002));
      cloudParticles3.forEach(p => (p.rotation.z -= 0.0003));

      if(Math.random() > 0.93 || flash.power > 100) {
        if(flash.power < 100) 
          flash.position.set(
            Math.random()*400,
            300 + Math.random() *200,
            100
          );
        flash.power = 50 + Math.random() * 500;
        // console.log("flash")
        // console.log(flash)
        // console.log("flash.power")
        // console.log(flash.power)
        // flash.intensity = 100
        flash.distance = 5000
      }
      // Update flash helper position to match flash light
      // flashHelper.position.copy(flash.position);

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} />;
};

export default ThreeScene;
